import styled from 'styled-components'
import tw from "tailwind.macro"
import { theme } from '../../../../tailwind.config'

export const SGroupLabel = styled.div``
export const SColumnHighlight = styled.div`
z-index: 1;
content: '';
position: absolute;
width: 19%;
left: 43%;
top: -5%;
height: 105%;
background: rgba(255,255,255,0.8);
box-shadow: 0 2px 20px 0 rgba(0,0,0,0.10);
`

export const STableRow = styled.div`
display: flex;
> ${SGroupLabel} {
  //line-height: 46px;
  //${tw`text-lg font-bold`}
}
> div {
  ${tw`text-sm`}
  line-height: 2.4rem;
  border-bottom: 1px solid rgb(243 244 246);
    
  .cell-highlight {
    background-color: #F9FAFB;
  }
}
@media(max-width: ${theme.extend.screenSizes.md.min}) {
  > div {
  &:first-child {
    min-width: auto;
  }
  &:not(:first-child) {
    min-width: auto;
  }
}
`

export const STableHead = styled(STableRow)`
z-index: 2;
position: relative;
> div {
  border-bottom: 0px;
}
`

export const STableBody= styled.div`
  display: block;
  z-index: 2;
  position: relative;
`
