import React from 'react'
import tw from "tailwind.macro"
import styled, {css} from 'styled-components'
import { theme } from '../../../../tailwind.config'

const Button = (props) => {
  const {color, size, children, tag} = props

  let sBase = css`
    color: white;
    transition: 100ms;
    ${tw`
      rounded-xl
      inline-block
      font-bold
    `}
    i {
      margin-left: 20px;
    }
  `
  const sSize = (size) => {
    switch(size) {
      case 'large':
        return css`
          padding: 12px 36px;
          ${tw` text-base`}
          &:hover {
            transform: translate(0,-2px);
            box-shadow: 0 9px 13px -6px rgba(0,0,0,.23)
          }
        `
      case 'small':
        return css`
          padding: 10px 20px;
          ${tw` text-sm`}
          &:hover {
            transform: translate(0,-2px);
            box-shadow: 0 7px 10px -4px rgba(0,0,0,.20)
          }
        `
      case 'medium':
        return css`
          padding: 12px 36px;
          ${tw` text-sm`}
          > i {
            ${tw` ml-3`}
          }
          &:hover {
            transform: translate(0,-2px);
            box-shadow: 0 7px 10px -4px rgba(0,0,0,.23)
          }
        `
      default:
        return css`
          padding: 3px;
        `
    }
  }

  const sColor = (color) => {
    switch(color) {
      case 'primary':
        return `
        background-image: linear-gradient(270deg, #6267ED 0%, #7A62ED 99%);
          color: white !important;
          &:hover {
            color: white;
          }
        `
      case 'primary-200':
        return `
          background-color: #5F71E9;
          color: white !important;
          &:hover {
            color: white;
          }
        `
      case 'primary-border':
        return `
          background-color: transparent;
          border: 3px solid ${theme.extend.colors.primary};
          color: ${theme.extend.colors.primary};
          &:hover {
            color: ${theme.extend.colors.primary};
          }
        `
      case 'green':
        return `
          background-color: ${theme.extend.colors.green};
          color: white;
          &:hover {
            color: white;
          }
        `
      case 'white':
        return `
          background-color: white;
          color: ${theme.extend.colors.primary};
          &:hover {
            color: ${theme.extend.colors.primary};
          }
        `
      default:
        return `
          background-color: ${theme.extend.colors.primary}
        `
    }
  }

  let SButton = null;
  if (tag === 'button') {
    SButton = styled.button`
      ${sBase}
      ${sSize(size)}
      ${sColor(color)}
    `
  } else {
    SButton = styled.a`
      ${sBase}
      ${sSize(size)}
      ${sColor(color)}
    `
  }


  return (<SButton {...props} >{children}</SButton>)
}

export default Button
