export const faqs = [
  {
      id: 'why_webhost',
      title: 'Why should I use SimpleBackups when my web host has a backup solution?',
      content: `The quality of web host backup solutions varies, and always inflexible in terms of storage and schedule. Plus, it’s never a good idea to put all your eggs in one basket.<br/><br/>
        When you back up your MySQL databases for example with SimpleBackups, you can easily reach them, store them where you want, keep them <strong>protected</strong>, and <strong>schedule automated backups</strong>. <br /><br />You don’t have to do create complex integrations, manual scripts, or check that your databases is being backed up. We’ll do the work and keep you <strong>notified</strong>, so you can focus on your work!`,
  },
  {
      id: 'why_backup_site',
      title: 'Why should I back up my website, database, or server?',
      content: `
      Data loss is inescapable. Protect your critical business files and data by taking regular backups to protect them against fire, natural disaster, or accidental deletion, which happens from time to time with no warnings.
      `,
  },
  {
    id: 'cost_agency',
    title: 'How much does SimpleBackups cost for an agency?',
    content: `We offer at least 100 backups for for all our paid plans, the difference in plans resides in some automation features as well as dedicated support you will get in the higher plans.<br/>
      Our plans start at $29/month which should cover all of your backup needs. Knowing you can charge this service to your customers, it is needless to say it will be amortized right away.
    `,
  },
  {
    id: 'what_mysql_backup_service',
    title: 'What is a MySQL Backup Service',
    content: `A MySQL Backup service, is a solution that will handle MySQL Backup task for its user.<br/>
        Using a service allows to rely on a third party to
        create and maintain the backup scripts and its necessary dependencies.<br/>
        The service will make sure MySQL Backups are well processed, will inform its user on backup status change and will
        offer all the tools & features to guarantee backups are running smoothly and fully automated.<br/><br/>
        It's an essential part of a proper disaster and recovery toolkit.
    `,
  },

  {
    id: 'what_mongodb_backup_service',
    title: 'What is a MongoDB Backup Service',
    content: `MongoDB Backup Service is a type of service that provides automated, secure, and reliable backups of MongoDB databases. MongoDB is an open-source, document-oriented database system that is widely used for storing and querying data.<br/><br/>
        A MongoDB Backup Service typically involves creating periodic copies of the entire MongoDB database, including all data and metadata, and storing the copies in a secure location. This allows the data to be restored in the event of a disaster, such as a hardware failure, cyber attack, or data corruption.<br/><br/>
        MongoDB Backup Services can be provided by a variety of different companies, including cloud service providers, specialized backups solutions like SimpleBackups. These services can be customized to meet the specific needs and budget of the organization, and can include features such as automated backups, disaster recovery, and scalable storage options.
    `,
  },
  {
    id: 'what_postgresql_backup_service',
    title: 'What is a PostgreSQL Backup Service',
    content: `A PostgreSQL Backup Service is a service that offers automated, secure, and reliable backups of PostgreSQL databases.<br/>
 This type of service typically creates periodic copies of the entire PostgreSQL database and stores them in a safe location, allowing the data to be restored in the event of a disaster.</br>
 PostgreSQL Backup Services can be customized to meet the specific needs of an organization, and are offered by various companies, including cloud service providers and specialized backup solutions like SimpleBackups.
    `,
  },
  {
    id: 'what_redis_backup_service',
    title: 'What is a Redis Backup Service',
    content: `A Redis Backup Service is a type of service that provides automated, secure, and reliable backups of Redis databases. Redis is an open-source, in-memory data structure store that is widely used for caching, real-time analytics, and message brokering.<br/><br/>
A Redis Backup Service typically involves creating periodic snapshots of the Redis database and storing the snapshots in a secure location. This allows the data to be restored in the event of a disaster, such as a hardware failure, cyber attack, or data corruption.<br/><br/>
Redis Backup Services can be provided by a variety of different companies, including cloud service providers, specialized backup solutions like SimpleBackups. These services can be customized to meet the specific needs of the organization, and can include features such as automated backups, disaster recovery, and scalable storage options.<br/><br/>`,
  },
  {
    id: 'what_storage_replication_service',
    title: 'What is a Storage Replication Service',
    content: `A storage replication, migration or backup service is a type of service that provides the ability to replicate data across multiple storage devices or locations. This allows organizations to create redundant copies of their data, providing increased reliability and availability in the event of a hardware failure, data corruption, or other disaster.
<br/><br/>
Storage replication services can be provided by a specialized SaaS like SimpleBackups. These services can be customized to meet the specific needs and budget of the organization, and can include features such as automated replication, real-time synchronization, and flexible storage options.
<br/><br/>
In summary, a storage replication service provides a way to create redundant copies of data, enabling organizations to protect their critical data and ensure its availability in the event of a disaster.`,
  },

  {
    id: 'what_saas_app_backup_service',
    title: 'What is a SaaS App Backup Service',
    content: `A SaaS (Software as a Service) app backup service is a service that allows you to create backups of your data and other assets stored in a SaaS (Software as a Service) application. SaaS applications are cloud-based software solutions that are typically accessed through a web browser, and they often store data in the cloud.
<br/><br/>
SaaS app backup services can help protect your data in case something happens to the original data stored in the SaaS application, such as data loss or corruption. They can also be useful for making copies of your data for use in other applications or for creating historical records of your data.
    `,
  },
  {
    id: 'how_mysql',
    title: 'How does SimpleBackups back up MySQL databases?',
    content: `SimpleBackups uses the MySQL backup command - mysqldump - to create, optimize, and maintain your backups. Your backups aren’t compressed so you can easily find the right files in a pinch.`,
  },
  // {
  //   id: 'support_mysql_incremental',
  //   title: 'What is the difference between MySQL full backups and incremental backups?',
  //   content: `A full backup is simialr to a snapshot of the whole database at the time of backup. An incremental backup only backs up the data that changed since the previous full backup. Combining both together, which you can easily do with SimpleBackups, provides you with the best backup strategy.`,
  // },
  {
    id: 'how_mongodb',
    title: 'How does SimpleBackups back up MongoDB database?',
    content: `SimpleBackups uses the MongoDB backup command - mongodump - to create, optimize, and maintain your backups. You can back up individual databases, or large collections with millions of documents. We’re cool with both!`,
  },
  {
    id: 'how_postgresql',
    title: 'How does SimpleBackups back up PostgreSQL databases?',
    content: `SimpleBackups uses the Postgres backup script to create, optimize, and maintain your backups. Your backups aren’t compressed so you can easily find the right files in a pinch.`,
  },
  {
    id: 'how_server',
    title: 'How does SimpleBackups work?',
    content: `It all happens in three steps: 
<ul class="list-disc ml-12">
  <li>Connect your server and choose which websites, files, or servers you want to back up</li>
  <li>Connect your storage (or use our own)</li>
  <li>Choose how often you want us to back up your files, and how many copies you want us to keep (e.g. only the last 2 backups)</li>
</ul>

And you’re done!
`,
  },
  {
    id: 'how_snapshot',
    title: 'How does Snapshot works?',
    content: `We have developed connectors to a list of infrastructure providers (DigitalOcean, AWS, ...).<br/>
This allows us to trigger some of their core functionalities which includes creating snapshot of servers and volumes.<br/>
Using SimpleBackups you can thus schedule and automate your snapshots by:
<ul class="list-disc ml-12">
  <li>Connect your server provider account</li>
  <li>Select the server/volume you want to schedule a snapshot for</li>
</ul>

And you’re done!
`,
  },
  {
    id: 'snapshot_store',
    title: 'Can I download a Snapshot?',
    content: `No. Snapshots are proprietary images, stored on your provider. Infrastructure providers do not allow for these images to be downloaded. This also mean that they can only be restored within that same provider infrastructure.
`,
  },
  {
    id: 'do_why',
    title: 'Why should I use SimpleBackups when DigitalOcean offers a backup solution?',
      content: `With DigitalOcean, you can only take weekly Droplet Snapshots.<br/>
        If you’re working on a dynamic project, that’s not enough.<br/>
        With SimpleBackups, unlock any backup intervals you need but also backup your Volume and Spaces or individual websites and databases.<br/>
        You can centralize all your backups under one roof, get the proper statistics and notification for all your projects.
    `,
  },
  {
    id: 'do_how',
    title: 'How do automated DigitalOcean Droplet Backups work?',
    content: `
      DigitalOcean Droplet Backup is an automated solution provided by DigitalOcean to take weekly Snapshots of your Droplets.<br/>
      With SimpleBackups you can get the same Droplet snapshots automated on the schedule you need (no-constraint) but also unlock backups for your Volume and Spaces.<br/>
      All you have to do is connect your DigitalOcean Account and select the resources you want to backup for a fraction of the price of the DigitalOcean Backup Solution.`,
  },
  {
    id: 'do_how_much_cost_backups',
    title: 'How much does DigitalOcean charge for backups?',
    content: `DigitalOcean charges as much as 20% of your server monthly cost for weekly server backups, we simply think it is expensive and at the same time not enough.
    `,
  },
  {
    id: 'do_regions',
    title: 'What DigitalOcean regions are supported?',
    content: `We support all <a href="/blog/digitalocean-supported-regions/">DigitalOcean datacenter regions</a>. 
    `,
  },
  {
    id: 'do_storage',
    title: 'What are my storage options?',
    content: `You can store your DigitalOcean backups wherever you’d like. It can be on your DigitalOcean Spaces or on any other <a href="/catalog/">provider</a>.`,
  },
  {
    id: 'how_long_create',
    title: 'How long does it take to schedule a backup?',
    content: `Assuming you have your server and database credentials available,
    we have seen it take less than 2 minutes and 14 seconds to install
    SimpleBackups and run the first backup! Hey! Think you can beat
    this time record? Let us know and we will give you a discount
    coupon :)
    `,
  },
  {
    id: 'is_secure',
    title: 'Will my data be safe with SimpleBackups?',
    content: `Yes, we comply with strict European data protection policies. The safety of your data is our top priority.`,
  },
  {
    id: 'how_encryption',
    title: 'How does backup encryption work?',
    content: `When you choose an encryption key for your file and database backups, the resulting backup will be encrypted via AES-256 and can only be decrypted using the same key you used.
    <br /><br />
    Behind the scenes, openssl is used take care of the encryption for you. This works best when backups are also streamed ("Backup Streaming" is enabled) since it will happen on the fly and the backup output will not even exist unencrypted at any point in time during backup states transition.
    `,
  },
  {
    id: 'backups_crucial',
    title: 'Backups are crucial!',
    content: `Don't risk losing your website or database and let us help you do
    the job. Minimize the effects of a disaster so that your business
    or website can continue to operate or quickly revert back to an
    earlier version of its data.
    `,
  },
  {
    id: 'backups_complex',
    title: 'Backups are not too complicated!',
    content: `Indeed, they were. That's what we're solving with SimpleBackups.
    We help you configure backups in under 3 minutes, then nothing
    else is needed from your side.
    <br />
    <i>
      3 minutes, it's 3 times less than what it takes you to drink a
      cup of coffee.
    </i>
    `,
  },
  {
    id: 'start_free',
    title: 'You can start for free!',
    content: `Still not convinced, not sure if it's worth the money?
    SimpleBackups has a free trial, as well as a free plan, just give
    it a try, we do not even ask for your credit card information or
    details about you when you sign up. Just an email and a password
    to login.
    `,
  },
  {
    id: 'what_os_supported',
    title: 'What operating systems & databases are supported?',
    content: `SimpleBackups supports Linux servers, MySQL, PostgreSQL, MongoDB, Redis databases. It supports every Linux distribution including: Amazon Linux, CentOS, Debian, Fedora, openSUSE, Red Hat, Ubuntu, etc...`,
  },
  {
    id: 'how_does_it_work',
    title: 'How does it work?',
    content: `You configure the parameters of your servers, define what storage you want to use and schedule your backups. 
      SimpleBackups then take care of getting your backup ran from your server to your selected storage directly and update
      you in real time on your backup status.
    `,
  },
  {
    id: 'what_is_retention',
    title: 'What does "backup retention" mean?',
    content: `The "retention" of your backup is the number of backups we keep in your storage before rotating it.
      Ex: If you have a backup rotation set to 2 for a backup configured to run once a week it means you'll always
      keep the last 2 backups, after the third week the first week' backup will be deleted (aka rotated).
    `,
  },
  {
    id: 'what_gets_installed',
    title: 'What gets installed on my server?',
    content: `
      Tbd
    `,
  },
  {
    id: 'what_is_simplestorage',
    title: 'What is "SimpleStorage"?',
    content: `SimpleStorage is a backup storage we offer to our users for which you do not have to pay any additional cost.<br/>
      This backups is owned by us and a secure, shared fraction of it is dedicated to your backups.<br/>
      At any time, you can upgrade your account to get more SimpleStorage or switch to your own storage solution if you prefer to keep everything under control.
    `,
  },
  {
    id: 'use_for_client',
    title: 'Can I use SimpleBackups for my clients’ backups?',
    content: `Absolutely! <br/>Many of our clients are agencies who use SimpleBackups to manage all their customers’ backups in one place, safely and efficiently.<br/>
    With "Teams" and "Projects" you can easily provide backup access to who you want. 
    `,
  },
  {
    id: 'use_for_client_2',
    title: 'Can I use SimpleBackups for my clients’ backups?',
    content: `Absolutely!<br/> It’s a great way to <strong>centralize all your backups</strong> so they’re easily reachable without wasting time.`,
  },
  {
    id: 'refund_policy',
    title: 'What is your refund policy?',
    content: `Monthly or yearly subscriptions, if you are unhappy with your subscription for any reason, email us at hello@simplebackups.com within 30 days and we'll refund you in full. You are never stuck.<br/>
    `,
  },
  {
    id: 'why_use_sbio',
    title: 'Why using SimpleBackups?',
    content: `<ul>
<li>- Manage all your backups in once place</li>
<li>- One single command to get started</li>
<li>- Own your data on your preferred storage</li>
</ul>
    `,
  },
  {
    id: 'use_for_other_things',
    title: 'Can I back up other things with SimpleBackups?',
    content: `Yes! If you’re looking for an all-in-one solution for all your databases, servers and buckets, SimpleBackups supports everything you might need:<br/>
<ul class="list-disc pl-12 mb-3">
<li>Server file backups</li>
<li>Server & volume snapshots</li>
<li>MySQL backups</li>
<li>MongoDB backups</li>
<li>PostgreSQL backups</li>
<li>Bucket (storage) backups</li>
</ul>
You can pick your preferred storage option, or host your backups with our own SimpleStorage.
    `,
  },
  {
    id: 'snapshot_where',
    title: 'Where are my Snapshots stored?',
    content: `All snapshots are stored on your provider cloud. SimpleBackups will hook into your provider API and orchestrate all backups. Snapshots never leave your provider.
`,
  },
  {
    id: 'another_question',
    title: 'Have any other question(s)?',
    content: `Just hit the chat icon at bottom right, and <span class="font-bold">we will answer</span>.`,
  },
  {
    id: 'additional_storage',
    title: 'What if I need more storage?',
    content: `No problem! Additional storage can be added through our addon system - you only pay for what you need.<br/> Don't forget that we only limit quota for SimpleStorage. Connecting your own storage account doesn't have any quota limit.`,
  },
  {
    id: 'pricing_additional_backups',
    title: 'What if I need more Backup Jobs?',
    content: `Once you've used all the backup jobs included in your plan, you can easily scale up by adding more jobs through our addon system. You only pay for what you need.`,
  },
  {
    id: 'pricing_backup_resource_definition',
    title: 'What is a Backup Job?',
    content: `A Backup Job, is the configuration of one backup in SimpleBackups. We don't limit the number of backup runs (sometimes referred as jobs) but only the number of Backup you configure.`,
  },
  {
    id: 'basic_plan_pricing',
    title: 'Can I get started for free?',
    content: `Yes! The Basic plan is free and allows you to setup your first backup job for free. If you need more, you can easily scale up by adding more jobs through our addon system. You only pay for what you need. Or you can also upgrade to PLUS plan for more features, flexibility and more cost-effective backups.`,
  },
  {
    id: 'can_download_backup',
    title: 'Can I download a Backup?',
    content: `Absolutely! You can download your backup using a signed link on your local machine or right on your server. All is managed easily from our UI or API.`,
  },
  {
    id: 'how_restore_backup',
    title: 'How can I restore a Backup?',
    content: `Simple: head to your backup log and you'll find the instruction to easily restore it where you need.`,
  },

  {
    id: 'how_sync_move_s3_backup',
    title: 'How do I back up my S3 bucket?',
    content: `Connect your S3 bucket to SimpleBackups. Connect another storage account, whether it is (Dropbox, Wasabi, Cloudflare R2, etc...), then create a backup job that syncs your data from S3 to the chosen destination.`,
  },

  {
    id: 'how_sync_move_drive_to_dropbox',
    title: 'Can I transfer data from Dropbox to Google Drive?',
    content: `Yes. You can use SimpleBackups to transfer data one-time or repeatedly from your Dropbox account to your Google Drive account.`,
  },

  {
    id: 'how_sync_move_s3_to_s3',
    title: 'Can I move S3 bucket from one region/account to another?',
    content: `Yes. You can create a cloud sync job on SimpleBackups that allows you to replicate your data from one S3 bucket to another. Can be on any account and any region.`,
  },

  {
    id: 'agency_',
    title: 'Can I move S3 bucket from one region/account to another?',
    content: `Yes. You can create a cloud sync job on SimpleBackups that allows you to replicate your data from one S3 bucket to another. Can be on any account and any region.`,
  },

  {
    id: 'security_certificate',
    title: 'What certification do you have?',
    content: `We are  ISO 27001 certified and follow the GDPR compliancy rules.<br/>`,
  },
  {
    id: 'security_data_stored',
    title: 'What data are your storing?',
    content: `Zero files, folders, config files are stored on any of our servers. Everything does happen on your own server and you do not need to provide SimpleBackups with root access/user.<br/> 
 Use our backup encryption feature which is available on any plan as well using your RSA keys, and then you can be super sure that no one can decrypt your backups except you.`,
  },
  {
    id: 'security_simplestorage',
    title: 'What about SimpleStorage security?',
    content: `Regarding our storage option (SimpleStorage), it is a secure AWS Bucket, so even if you choose to store your data on SimpleStorage, your backups never pass by any third-party or pass by our own servers (we recommend that you use your own storage actually but provide SimpleStorage for those who want a quick option). `,
  },
  {
    id: 'security_what_if_account_is_compromised',
    title: 'What if my SimpleBackups account is compromised or deleted?',
    content: `If your SimpleBackups account is compromised, cancelled, deleted, your backups are not deleted. In fact, we do not provide an option to delete/purge backups on an external storage. They are only rotated when it is time to do so based on your retention settings. Some users also use the Object Lock feature on their storage provider to prevent backup deletion by any means.`,
  },
  {
    id: 'security_if_leave_access_backups',
    title: 'Would I lose access to my backups if I leave SimpleBackups?',
    content: `We do not use any propriety backup software - so if decide to leave SimpleBackups at any time, your backups are always there and accessible on your own storage. No need for SimpleBackups to retrieve them.`,
  },

  {
    id: 'gdpr_compliance',
    title: 'Is SimpleBackups GDPR compliant?',
    content: `Yes, we are! <br/>We're based in Europe and serve customers across the globe with the strongest compliance rules.<br/><br/>
    Find out more about our <a class="text-primary" href="https://simplebackups.com/security-first/">security-first approach</a> and how we <a class="text-primary" href="https://simplebackups.com/privacy/#GDPR">comply with GDPR</a>. We also have a <a class="text-primary" href="https://simplebackups.com/gdpr-sheet">GDPR summary sheet</a> to clarify the essentials.`,
  },

  // Google Cloud Storage
  {
    id: 'gcs_migrate_from_aws',
    title: 'Can I migrate my data from AWS to Google Cloud Storage?',
    content: `Yes! SimpleBackups supports multiple different storage providers and allows you to migrate and back up your data without any additional effort.`,
  },
  {
    id: 'gcs_migrate_to_provider',
    title: 'Can I also migrate from Google Cloud Storage to other providers?',
    content: `Absolutely. With our tool, you can transfer your data between multiple providers. Check out our blog, where we describe in detail specific data migration cases`,
  },
  {
    id: 'gcs_migrate_to_gcs',
    title: 'How to migrate your data between your old provider and Google Cloud storage using SimpleBackups?',
    content: `Here’s a brief description of the process:
    <ul>
    <li>- First, create a Google Cloud Storage bucket and generate access keys.</li><li>- Then, in SimpleBackups, add your old storage provider as a source and Google Cloud Storage as a destination.</li><li>- Enter the access keys and configure the backup frequency and retention.</li><li> -Start the backup and monitor the progress.</li></ul> 
    Once completed, verify the backup files in the Google Cloud Storage bucket.<br/> Finally, update your application to point to the new storage location.`,
  },

  // Leviia
  {
    id: 'leviia_migrate_from_aws',
    title: 'Can I migrate my data from AWS to Leviia?',
    content: `Yes! SimpleBackups supports multiple different storage providers and allows you to migrate and back up your data without any additional effort.`,
  },
  {
    id: 'leviia_migrate_to_provider',
    title: 'Can I also migrate from Leviia Storage to other providers?',
    content: `Absolutely. With our tool, you can transfer your data between multiple providers. Check out our blog, where we describe in detail specific data migration cases`,
  },


  // Server
  {
    id: 'what_server_backup_service',
    title: 'What is server backup, and why is it important?',
    content: `Server backup is the process of creating a copy of data on a server to protect against data loss or corruption.<br/>
It's important because servers often store critical data that can be costly or impossible to recover if lost, such as business documents, customer information, or financial records.<br/>
Backup ensures that you have a duplicate of your data that can be restored in case of an emergency.
    `,
  },
  {
    id: 'how_often_server_backup',
    title: 'How often should I back up my server?',
    content: `The frequency of server backup depends on how often your data changes and how critical it is.<br/>
For example, if your data changes frequently, you may want to back up daily or hourly.<br/>
On the other hand, if your data changes infrequently, weekly or monthly backups may suffice.<br/>
Ultimately, the goal is to ensure that you can recover data from a backup that is recent enough to minimize data loss in the event of a failure.`,
  },
  {
    id: 'what_types_server_backup',
    title: 'What are the different types of server backup?',
    content: `There are several types of server backup, including full backups, incremental backups, and differential backups.<br/>
A full backup involves copying all data from the server, while incremental backups only copy data that has changed since the last backup, and differential backups copy all data that has changed since the last full backup.<br/>
 There are also cloud-based backups, which involve backing up data to a remote server.`,
  },
  {
    id: 'how_long_keep_server_backup',
    title: 'How long should I keep server backups?',
    content: `The length of time you should keep server backups depends on your organization's policies and compliance requirements.<br/>
In general, it's a good idea to keep multiple backups that cover different time periods to ensure that you can recover data from a specific time.<br/>
For example, you may want to keep daily backups for a week, weekly backups for a month, and monthly backups for a year.<br/>
After that, you may want to consider deleting older backups to free up storage space.`,
  },


  // DBAAS
  {
    id: 'what_is_dbaas_backups',
    title: 'What is a DBaaS backup service?',
    content: `A database backup service is a solution that facilitates the creation, storage, and management of backup copies of your database's data and configurations. It ensures data integrity and recoverability by allowing you to create duplicate copies of your database at specific points in time. This service helps safeguard your critical data from accidental deletion, data corruption, hardware failures, and other unforeseen incidents.`,
  },
  {
    id: 'what_is_dbaas',
    title: 'What does DBaaS stand for?',
    content: `DBaaS stands for Database-as-a-Service. It's a cloud computing service model that provides users with access to a managed database environment. Instead of setting up and maintaining their own database infrastructure, users can leverage a DBaaS provider's resources to store, manage, and operate databases.`,
  },
  {
    id: 'what_is_managed_database',
    title: 'What is a managed database?',
    content: `A managed database refers to a database system that is handled and maintained by a third-party provider. This provider takes care of tasks such as setup, configuration, security, maintenance, monitoring, and performance optimization. Users can interact with the database while the provider manages its infrastructure and ensures its smooth operation.`,
  },

  // DBAAS - PLANETSCALE

  {
    id: 'is_planetscale_built_in_backup_sufficient',
    title: 'Is PlanetScale\'s own backup service sufficient for my data protection needs?',
    content: `PlanetScale's backup service is designed to meet the data protection and recovery needs of applications built on their platform.<br/>
        While PlanetScale built-in solution is great for development environment it is not suitable for a production backup policy: relying on your database provider's to also manage your backups is a risky strategy that can lead to data loss.
        It's important to align your backup strategy with your business's specific requirements. 
        Depending on your compliance and risk considerations, you might choose to complement PlanetScale's backup service with additional backup solutions or disaster recovery strategies.
    `,
  },

  {
    id: 'supabase_what_is',
    title: 'What is Supabase?',
    content: `Supabase is an open-source Firebase alternative, offering a suite of tools and services to build and manage modern web and mobile applications. It provides a variety of features, including a PostgreSQL database, authentication, real-time subscriptions, and instant APIs. Supabase aims to give developers a highly scalable, reliable, and easy-to-use platform for their application development needs.
    `,
  },
  {
    id: 'supabase_backup_service',
    title: 'What is a Supabase backup service?',
    content: `
    A Supabase Backup Service, is a third-party service designed to back up data stored in Supabase. This service operates independently from Supabase’s own backup mechanisms, providing an additional layer of data protection. Its advantages include enhanced data security through redundancy, customizable backup schedules, and the ability to tailor backup strategies to specific needs, offering greater control over data management and recovery processes.
    `,
  },

  // NEON

  {
    id: 'neon_what_is',
    title: 'What is Neon?',
    content: `Neon is a fully managed serverless PostgreSQL that offers cool features such as database branching, and bottomless storage. Neon storage uses the "copy-on-write" technique to deliver database branching, online checkpointing, and point-in-time restore. This eliminates expensive full-data backup and restore operations required with traditional database-as-a-service systems.
    `,
  },
  {
    id: 'neon_backup_service',
    title: 'What is a Neon backup service?',
    content: `
    A Neon Backup Service, is a third-party service designed to back up data stored in Neon. This service operates independently from Neon’s own backup mechanisms, providing an additional layer of data protection. Its advantages include enhanced data security through redundancy, customizable backup schedules, and the ability to tailor backup strategies to specific needs, offering greater control over data management and recovery processes.
    `,
  },

  {
    id: 'what_is_multicloud',
    title: 'What is Multi-Cloud?',
    content: `Multi-cloud refers to the use of multiple cloud computing services from different providers.<br/>
        Multi-cloud environments are becoming increasingly popular as organizations seek to take advantage of the benefits offered by different cloud providers, such as cost savings, scalability, and flexibility.<br/>
        
        In the context of backup, multi-cloud refers to the use of multiple cloud storage providers to store backup copies of your data. This can help ensure that your data is protected against data loss or corruption, as well as provide additional redundancy in case one provider experiences an outage or other issue.
    `,
  },

  {
    id: 'can_i_restore_other_provider',
    title: 'Can I restore a backup to another provider?',
    content: `Yes! You can restore your backups to any provider you want. You can even restore your backups to a different provider than the one you used to create them.<br/>
    Just follow our restore instructions and you'll be good to go.
    `,
  },


  /// Apps

  // ***************************
  // GitHub Backup
  // ***************************
  {
    id: 'what_github_backup_service',
    title: 'What is a GitHub Backup Service',
    content: `A GitHub backup service is a service that allows you to create backups of your repositories on GitHub but also all its associated meta data. These backups can be used to ensure that you have a copy of your code and other files in case something happens to the original repository, such as data loss or corruption.
              <br/><br/>
              There are a number of different services that offer GitHub backup capabilities. Some of these are standalone services that you can use to create backups of your repositories, while others are integrated into other tools and services that you might already be using.
              <br/><br/>
              To use a GitHub backup service, you will typically need to provide the service with access to your GitHub account. This can typically be done by connecting your account to the service using your GitHub login credentials. Once your account is connected, you can then select the repositories that you want to back up and specify the backup schedule and other settings.`,
  },
  {
    id: 'github_is_there_a_data_limit',
    title: 'Is there a limit to the amount of data I can back up with GitHub Backup?',
    content: `We do not limit the amount of data you can backup. You can backup as much data as you want, as often as you want.`,
  },
  {
    id: 'github_what_data_is_backed_up',
    title: 'What GitHub data can be backed up?',
    content: `With SimpleBackups you can back up all your GitHub respositories but also all your metadata.
    <br/>
    Practically this means: repositories, gists, issues, pull requests, releases, wikis, labels, milestones, LFS objects and projects.`,
  },
  {
    id: 'github_server_backup_service',
    title: 'Do I need a server to run the GitHub backup?',
    content: `Short answer: No. SimpleBackups offers a serverless solution to backup your GitHub data.<br/>
        But for those who needs to run their GitHub backup on their own server you can always run your backup from your own server and this without the need of any manual setup.`,
  },
  {
    id: 'github_automatic_backup',
    title: 'Can I backup all my GitHub repositories automatically, without manual intervention?',
    content: `Yes! When configuring your backup you'll be able to select your the repositories you want to back up, but also define exclusion rules to exclude repositories you don't want to backup.
    <br/> This way you can set it up once and forget about it and new repositories will automatically be backed up based on your preferences.
    `,
  },
  {
    id: 'github_download',
    title: 'Can I download my GitHub backup?',
    content: `Of course! Your backups can be downloaded as a compressed file, right from the interface or directly from your storage provider.
    `,
  },
  {
    id: 'github_retention',
    title: 'How long are my backup kept?',
    content: `It's up to you. We have a highly customizable retention policy, meaning you can define it yourself, without any constraint.
    `,
  },
  {
    id: 'github_orginization_backup',
    title: 'Do you support GitHub Organization account backup?',
    content: `Yes! Both GitHub Organization account and personal are supported.
    `,
  },

  // *****************
  // GitLab Backup
  // *****************

  {
    id: 'what_gitlab_backup_service',
    title: 'What is a GitLab Backup Service',
    content: `A GitLab backup service is a solution that allows you to create backups of your repositories and other data stored on GitLab. These backups can be used to ensure that you have a copy of your code, merge request, issues (...) in case something happens to the original repository, such as data loss or corruption.`,
  },
  {
    id: 'gitlab_is_there_a_data_limit',
    title: 'Is there a limit to the amount of data I can back up with GitLab Backup?',
    content: `We do not limit the amount of data you can backup with GitLab backups. You can backup as much data as you want, as often as you want.`,
  },
  {
    id: 'gitlab_data_type',
    title: 'What GitLab data can be backed up?',
    content: `You can back up all your GitLab repositories, but also all your metadata which includes issues, merge requests, snippets, commits and all available metadata.`,
  },
  {
    id: 'gitlab_server_backup_service',
    title: 'Do I need a server to run GitLab backup?',
    content: `No you don't! We offer a "Serverless" backup service, which means everything is triggered from our infrastructure. Still if you want the backup process to be triggered from within your infrastructure it's totally doable, just connect your server to SimpleBackups.`,
  },
  {
    id: 'gitlab_download',
    title: 'Can I download my GitLab backups?',
    content: `Yes! All your backups are stored on your storage provider and can be downloaded at any time from the user interface.`,
  },
  {
    id: 'gitlab_automated',
    title: 'Can I schedule the GitLab backups to run automatically on new repos?',
    content: `Yes! In you backup you can select "auto-discover" option, which will include all newly created repositories in your backup. Your backup will then run on schedule, and include all new repositories.`,
  },

  // *****************
  // Knack Backup
  // *****************
  {
    id: 'what_knack_backup_service',
    title: 'What is a Knack Backup Service',
    content: `Knack Backup service refers to a comprehensive backup solution specifically designed for users of Knack, a popular online database and application builder.<br/><br/>
This backup system ensures that all data stored within a Knack application, including records, structures, and configurations, is securely backed up and easily recoverable.<br/><br/>
It's ideal for safeguarding against data loss due to system failures, accidental deletions, or unforeseen events. With Knack Backup, users can have peace of mind knowing their valuable data is protected and can be restored quickly to maintain business continuity.
    `,
  },
  {
    id: 'knack_is_there_a_data_limit',
    title: 'Is there a limit to the amount of data I can back up with Knack Backup?',
    content: `We do not limit the amount of data you can backup. You can backup as much data as you want, as often as you want.`,
  },


  {
    id: 'what_notion_backup_service',
    title: 'What is a Notion Backup Service',
    content: `A Notion backup service is a tool that automatically saves copies of your Notion workspaces at regular intervals.<br/>
                This ensures that you have secure and up-to-date backups of your data, which can be restored in case of accidental deletion, data corruption, or any other issues that might cause data loss.
    `,
  },
  {
    id: 'what_notion_backup_data_types',
    title: 'What Notion data types can be backed up?',
    content: `Short answer: Everything!<br/>
                Long answer: Notion Backup services can back up all types of Notion data, including pages, databases, files, and more. This ensures that all your Notion content is protected and can be easily restored when needed.
    `,
  },
  {
    id: 'notion_is_there_a_data_limit',
    title: 'Is there a limit to the amount of data I can back up with Notion Backup?',
    content: `We do not limit the amount of data you can back up. You can backup your entire Notion workspace or any individual elements you need.`,
  },


  //// Snapshot PRoviders


  // Hetzner
  {
    id:'hetzner_what_is_snapshot',
    title: 'What is a Hetzner Snapshot?',
    content: `A Hetzner Snapshot is a point-in-time copy of a Hetzner Cloud server or volume. It captures the state of the server or volume at a specific moment, including the operating system, installed software, and data. 
      Snapshots are useful for creating backups, restoring to a previous state, or migrating to a different server or volume.`,
  },
  {
    id: 'hetzner_where_are_snapshots_stored',
    title: 'Where are my Hetzner Snapshots stored?',
    content: `Hetzner Snapshots are stored on the Hetzner Cloud platform. They are stored in the same data center as the server or volume from which the snapshot was created.`,
  },

  // Exoscale
  {
    id: 'exoscale_what_is_snapshot',
    title: 'What is an Exoscale Snapshot?',
    content: `An Exoscale Snapshot is a point-in-time copy of an Exoscale Compute instance or volume.`,
  },

  // Scaleway
  {
    id: 'scaleway_what_is_snapshot',
    title: 'What is a Scaleway Snapshot?',
    content: 'A Scaleway snapshot is a full, read-only copy of a storage volume at a specific point in time, allowing for data backup and recovery. It enables users to restore their system or data to the snapshot\'s state whenever needed.'
  },
  {
    id: 'upcloud_what_is_snapshot',
    title: 'What is an UpCloud Snapshot?',
    content: 'An UpCloud snapshot is a complete backup of a storage device, capturing all the data and system state at a specific moment in time, which can be used for efficient data recovery or to quickly deploy new instances with identical configurations. It allows users to safeguard their data and ensure operational continuity with minimal downtime.'
  }


]
